import { template as template_f20824624a894417aeb507b0f2b1f41d } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_f20824624a894417aeb507b0f2b1f41d(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
