import { template as template_8ff8dfa77baa417a93355f6b02d1011d } from "@ember/template-compiler";
const FKLabel = template_8ff8dfa77baa417a93355f6b02d1011d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
