import { template as template_7e0d0b432509403b8c28b53d15449b0e } from "@ember/template-compiler";
const FKControlMenuContainer = template_7e0d0b432509403b8c28b53d15449b0e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
